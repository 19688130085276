export function formatDate(date: number): string {
  const final = { hour: '00', min: '00', sec: '00' };

  const sec = date % 60;
  if (sec < 10) {
    final.sec = '0' + sec.toString();
  } else {
    final.sec = sec.toString();
  }

  date = Math.floor((date - sec) / 60); // Nombre de minutes (partie entière)
  const min = date % 60;
  if (min < 10) {
    final.min = '0' + min.toString();
  } else {
    final.min = min.toString();
  }

  date = Math.floor((date - min) / 60); // Nombre d'heures (entières)
  const hour = date % 24;
  if (hour < 10) {
    final.hour = '0' + hour.toString();
  } else {
    final.hour = hour.toString();
  }

  date = Math.floor((date - hour) / 24); // Nombre de jours restants
  const day = date;
  if (day > 0) {
    final.hour = (24 * day + hour).toString();
  }

  return final.hour + ':' + final.min + ':' + final.sec;
}
